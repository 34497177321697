<template>
    <div class="grid p-jc-center p-ai-center vertical-container">
        <div class="col-12 md:col-8 lg:col-6">
            <Card>
                <template #title>
                    {{title}}
                </template>
                <template #content>
                    <div class="p-fluid">
                        <div class="field">
                            <label>Nama <span class="p-error">*</span></label>
                            <InputText v-model="authForm.nama" placeholder="Nama Lengkap + Gelar" autofocus
                                :class="{ 'p-invalid': authForm.errors.has('nama') }" />
                            <small class="p-error" v-show="authForm.errors.has('nama')">
                                {{ authForm.errors.get('nama') }}
                            </small>
                        </div>
                        <div class="grid">
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>NIK <span class="p-error">*</span></label>
                                    <InputText v-model="authForm.nik"
                                        :class="{ 'p-invalid': authForm.errors.has('nik') }" />
                                    <small class="p-error" v-show="authForm.errors.has('nik')">
                                        {{ authForm.errors.get('nik') }}
                                    </small>
                                </div>
                            </div>
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>Nama Instansi <span class="p-error">*</span></label>
                                    <InputText v-model="authForm.nama_instansi"
                                        :class="{ 'p-invalid': authForm.errors.has('nama_instansi') }" />
                                    <small class="p-error" v-show="authForm.errors.has('nama_instansi')">
                                        {{ authForm.errors.get('nama_instansi') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>No NUPTK/NIDN</label>
                                    <InputText v-model="authForm.no_n"
                                        :class="{ 'p-invalid': authForm.errors.has('no_n') }" />
                                    <small class="p-error" v-show="authForm.errors.has('no_n')">
                                        {{ authForm.errors.get('no_n') }}
                                    </small>
                                </div>
                            </div>
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>Tanggal Lahir <span class="p-error">*</span></label>
                                    <Calendar v-model="authForm.tgl_lahir" placeholder="YYYY-MM-DD" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange"
                                        :class="{ 'p-invalid': authForm.errors.has('tgl_lahir') }" />
                                    <small class="p-error" v-show="authForm.errors.has('tgl_lahir')">
                                        {{ authForm.errors.get('tgl_lahir') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>No HP <span class="p-error">*</span></label>
                                    <InputText v-model="authForm.no_hp"
                                        :class="{ 'p-invalid': authForm.errors.has('no_hp') }" />
                                    <small class="p-error" v-show="authForm.errors.has('no_hp')">
                                        {{ authForm.errors.get('no_hp') }}
                                    </small>
                                </div>
                            </div>
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>Email <span class="p-error">*</span></label>
                                    <InputText v-model="authForm.email"
                                        :class="{ 'p-invalid': authForm.errors.has('email') }" />
                                    <small class="p-error" v-show="authForm.errors.has('email')">
                                        {{ authForm.errors.get('email') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>Password <span class="p-error">*</span></label>
                                    <Password v-model="authForm.password" mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})." toggleMask
                                        :class="{ 'p-invalid': authForm.errors.has('password') }">
                                        <template #header>
                                            <h6>Pilih kata sandi</h6>
                                        </template>
                                        <template #footer>
                                            <Divider />
                                            <p class="mt-2">Saran</p>
                                            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                                <li>Setidaknya satu huruf kecil</li>
                                                <li>Setidaknya satu huruf besar atau satu angka</li>
                                                <li>Minimal 6 karakter</li>
                                            </ul>
                                        </template>
                                    </Password>
                                    <small class="p-error" v-show="authForm.errors.has('password')">
                                        {{ authForm.errors.get('password') }}
                                    </small>
                                </div>
                            </div>
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>Confirm Password <span class="p-error">*</span></label>
                                    <Password v-model="authForm.password_confirmation" :feedback="false" toggleMask
                                        :class="{ 'p-invalid': authForm.errors.has('password_confirmation') }" />
                                    <small class="p-error" v-show="authForm.errors.has('password_confirmation')">
                                        {{ authForm.errors.get('password_confirmation') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label>Alamat <span class="p-error">*</span></label>
                            <Textarea v-model="authForm.alamat"
                                :class="{ 'p-invalid': authForm.errors.has('alamat') }" />
                            <small class="p-error" v-show="authForm.errors.has('alamat')">
                                {{ authForm.errors.get('alamat') }}
                            </small>
                        </div>
                        <div class="grid">
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>Provinsi</label>
                                    <Dropdown v-model="authForm.province_id" optionValue="id" optionLabel="name" :filter="true"
                                        @change="getRegencies(authForm.province_id, null, true)"
                                        :options="relations.provinces"
                                        :class="{ 'p-invalid': authForm.errors.has('province_id') }" />
                                    <small class="p-error" v-show="authForm.errors.has('province_id')">
                                        {{ authForm.errors.get('province_id') }}
                                    </small>
                                </div>
                            </div>
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>Kabupaten/Kota</label>
                                    <Dropdown v-model="authForm.regency_id" optionValue="id" optionLabel="name" :filter="true"
                                        @change="getDistricts(authForm.regency_id, null, true)"
                                        :options="relations.regencies"
                                        :class="{ 'p-invalid': authForm.errors.has('regency_id') }" />
                                    <small class="p-error" v-show="authForm.errors.has('regency_id')">
                                        {{ authForm.errors.get('regency_id') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>Kecamatan</label>
                                    <Dropdown v-model="authForm.district_id" optionValue="id" optionLabel="name" :filter="true"
                                        @change="getVillages(authForm.district_id)"
                                        :options="relations.districts"
                                        :class="{ 'p-invalid': authForm.errors.has('district_id') }" />
                                    <small class="p-error" v-show="authForm.errors.has('district_id')">
                                        {{ authForm.errors.get('district_id') }}
                                    </small>
                                </div>
                            </div>
                            <div class="sm:col-6 col-12">
                                <div class="field">
                                    <label>Desa/Kelurahan</label>
                                    <Dropdown v-model="authForm.village_id" optionValue="id" optionLabel="name" :filter="true"
                                        :options="relations.villages"
                                        :class="{ 'p-invalid': authForm.errors.has('village_id') }" />
                                    <small class="p-error" v-show="authForm.errors.has('village_id')">
                                        {{ authForm.errors.get('village_id') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="grid field">
                            <div class="col-8">
                                <div class="field-checkbox" style="margin-bottom:0">
                                    <Checkbox v-model="authForm.agree" id="agree" :binary="true" 
                                        :class="{ 'p-invalid': authForm.errors.has('agree') }" />
                                    <label for="agree">Saya setuju dengan <a href="#">Syarat & Ketentuan</a></label>
                                </div>
                                <small class="p-error" v-show="authForm.errors.has('agree')">
                                    {{ authForm.errors.get('agree') }}
                                </small>
                            </div>
                            <div class="col-4">
                                <Button label="DAFTAR" @click="register" :disabled="authForm.busy" :icon="(authForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'" iconPos="right" />
                            </div>
                        </div>
                    </div>
                    <p class="mb-1">
                        Sudah punya akun? klik <router-link to="/login">Masuk</router-link>
                    </p>
                </template>
            </Card>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Daftar",
            api: '/api/auth',
            authForm: new this.$Form({
                nama: null,
				nik: null,
				nama_instansi: null,
				no_n: null,
				tgl_lahir: null,
				no_hp: null,
				email: null,
				password: null,
				password_confirmation: null,
				alamat: null,
				province_id: null,
				regency_id: null,
				district_id: null,
				village_id: null,
                agree: false,
            }),
            loginForm: new this.$Form({
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
                email: null,
                password: null,
                remember_me: false,
            }),
            relations: {
				provinces: null,
				regencies: null,
				districts: null,
				villages: null,
			},
        }
    },
    methods: {
        getProvinces(current_id, resetTree = false){
			this.$Progress.start();
			this.$http.get(this.api + '/get/provinces')
			.then((response) => {
				this.$Progress.finish();
				this.relations.provinces = response.data;
				if(current_id == null){
					this.authForm.province_id = null;
				} else {
					this.authForm.province_id = current_id;
				}
				if(resetTree == true){
					this.authForm.regency_id = null;
					this.authForm.district_id = null;
					this.authForm.village_id = null;
					this.relations.regencies = null;
					this.relations.districts = null;
					this.relations.villages = null;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getRegencies(province_id, current_id, resetTree = false){
			this.$Progress.start();
			this.$http.get(this.api + '/get/regencies', {
				params: {
					province_id: province_id,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.relations.regencies = response.data;
				if(current_id == null){
					this.authForm.regency_id = null;
				} else {
					this.authForm.regency_id = current_id;
				}
				if(resetTree == true){
					this.authForm.district_id = null;
					this.authForm.village_id = null;
					this.relations.districts = null;
					this.relations.villages = null;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getDistricts(regency_id, current_id, resetTree = false){
			this.$Progress.start();
			this.$http.get(this.api + '/get/districts', {
				params: {
					regency_id: regency_id,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.relations.districts = response.data;
				if(current_id == null){
					this.authForm.district_id = null;
				} else {
					this.authForm.district_id = current_id;
				}
				if(resetTree == true){
					this.authForm.village_id = null;
					this.relations.villages = null;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getVillages(district_id, current_id){
			this.$Progress.start();
			this.$http.get(this.api + '/get/villages', {
				params: {
					district_id: district_id,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.relations.villages = response.data;
				if(current_id == null){
					this.authForm.village_id = null;
				} else {
					this.authForm.village_id = current_id;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},

        register(){
            this.$Progress.start();
            this.authForm.post(this.api + '/register-member')
            .then((response) => {
                this.$Progress.finish();
                this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
                this.loginForm.email = this.authForm.email,
                this.loginForm.password = this.authForm.password,
                this.loginForm.post(this.api + '/login')
                .then((response) => {
                    this.authForm.clear();
                    this.authForm.reset();
                    this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
                    this.$store.commit('setGuard', response.data.data);
                    this.$store.dispatch('getUserCredentials').then(() => {
                        this.$router.push('/dashboard');
                    });
                })
                .catch((error) => {
                    this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
                });
            })
            .catch((error) => {
                this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },
    },
    computed: {
        yearRange() {
			const now = new Date().getFullYear();
			const past = now-100;
			const yearRange = past+':'+now;
            return yearRange;
        }
    },
    created(){
		this.$e.emit('updateTitle', this.title);
	},
    mounted() {
		this.getProvinces();
	},
}
</script>